<template>
  <NuxtLink
    v-bind="$attrs"
    ref="uiHtmlListItemElement"
    prefetch
    class="group flex flex-wrap border-b border-blue-100"
    :class="[
      {
        'p-7 text-white': Boolean(props.variant),
        'h-full flex-row flex-nowrap gap-x-3 motion-reduce:transition-none':
          !Boolean(props.variant),
      },
      classGradient,
      props.sizeVariant === 'dense' ? 'py-3' : 'pb-2.5 pt-6',
    ]"
    :to="props.slug"
  >
    <div
      class="aspect-news-in-list-mobile shrink-0 overflow-hidden"
      :class="{
        'w-full': Boolean(props.variant),
        'w-24 self-start': !Boolean(props.variant),
      }"
    >
      <UiImage
        v-if="parseImage(props.media?.[0]?.images?.defaultImage || props.media)"
        v-bind="
          parseImage(props.media?.[0]?.images?.defaultImage || props.media)
        "
        loading="lazy"
        width="424"
        height="285"
        class="size-full object-cover"
        fetchpriority="low"
      />
    </div>
    <slot name="textOnMobile">
      <UiNewsListItemText
        class="flex w-full flex-col justify-end"
        :class="{
          'pt-7': Boolean(props.variant),
        }"
        v-bind="{
          ...props,
          datetime: props.sizeVariant === 'dense' ? '' : props.datetime,
          tags: props.sizeVariant === 'dense' ? [] : props.tags,
          withBackground: Boolean(props.variant),
          addMoreInfo: props.sizeVariant === 'dense' ? false : props.addMoreInfo,
        }"
      >
        <HtmlParser
          v-if="teaser"
          :html="teaser"
          tag="p"
          :class="{ 'text-white': Boolean(props.variant) }"
          wysiwyg
        />
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="slotData"
          />
        </template>
      </UiNewsListItemText>
    </slot>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import UiImage from '../../../UiImage/UiImage.vue'
import UiNewsListItemText from '../UiNewsListItemText/UiNewsListItemText.vue'
import type { UiNewsItem, UiNewsListItemImage } from '../UiNewsListItem.types'

const props = withDefaults(defineProps<UiNewsItem>(), {
  teaser: '',
  datetime: '',
  datetimeShort: '',
  tags: () => [],
  categories: () => [],
  readTime: undefined,
  moreLink: '',
  variant: '',
  addMoreInfo: true,
  labels: null,
  colorOfGradient: '',
  sizeVariant: 'comfort',
})

const uiHtmlListItemElement = ref<HTMLInputElement | null>(null)
defineExpose({
  uiHtmlListItemElement,
})

const classGradient = computed(() => {
  switch (props.colorOfGradient) {
    case 'gradient-blue':
      return 'bg-gradient-blue'
    case 'gradient-green':
      return 'bg-gradient-green'
    case 'gradient-bright-blue':
      return 'bg-gradient-bright-blue'
    case 'gradient-purple':
      return 'bg-gradient-purple'
    case 'gradient-blue-dark':
      return 'bg-gradient-dark-blue'
    default:
      return ''
  }
})

function parseImage(image: UiNewsListItemImage | string) {
  if (typeof image === 'string') {
    return {
      src: image,
    }
  }

  if (typeof image === 'object' && image !== null && !Array.isArray(image)) {
    if (Object.keys(image.cropVariants.listViewImage).length !== 0) {
      return {
        src: image.cropVariants?.listViewImage?.publicUrl || '',
      }
    }
    else {
      return {
        src: image?.publicUrl || '',
      }
    }
  }

  return null
}
</script>
